






































import { Component, Vue } from 'vue-property-decorator'
import HeaderLogo from './component/header-logo'
import SliderMenu from './component/SliderMenu.vue'
import HeaderBar from './component/HeaderBar.vue'
import BreadCrumb from './component/BreadCrumb.vue'

import { microManager } from '@/micro'
import { NaviStateModule } from '@/store/navi_state'

@Component({
  name: 'PageMainLayout',
  components: {
    SliderMenu,
    HeaderBar,
    HeaderLogo,
    BreadCrumb
  }
})
export default class PageMainLayout extends Vue {
  created() {
    console.log('PageMainLayout created')
    // 启动qiankun
    microManager.start({
      //沙盒机制导致全局的Modal等无更好的办法看控制，暂时注释掉
      // sandbox: { strictStyleIsolation: true },
      prefetch: 'all'
    })
  }

  get menuCollapsedState() {
    return NaviStateModule.menuCollapsed === 'index'
      ? false
      : NaviStateModule.menuCollapsed
  }

  get menuCollapsedStateOrigin() {
    return NaviStateModule.menuCollapsed
  }

  get menuCollapsedStateIndex() {
    return NaviStateModule.menuCollapsed === 'index'
  }

  mounted() {
    console.log('PageMainLayout mounted')
  }
}
