var render, staticRenderFns
import script from "./SliderMenu.vue?vue&type=script&lang=tsx&"
export * from "./SliderMenu.vue?vue&type=script&lang=tsx&"
import style0 from "./SliderMenu.vue?vue&type=style&index=0&id=7f13b274&lang=less&scoped=true&"
import style1 from "./SliderMenu.vue?vue&type=style&index=1&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f13b274",
  null
  
)

export default component.exports